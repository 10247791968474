/*
IPFS API DETAILS
*/
//import { create as ipfsHttpClient } from 'ipfs-http-client';
//export const client = ipfsHttpClient('http://IP_ADDRESS_OF_THIS_SERVER:5001');

//old export var nftcollection = "0x934115DdC0E33E0f36E32fd10EB4445dD9fAC9Fb"
//new below
//export var nftcollection = "0x67ec957D2fa5d43B1c81d83E93850f9D858c0F92"
//latest working
/*
export var nftcollection = "0xDC89Ef511F9cD9B79Fc5E5934D083544CfADe791"
*/

//old export var nftresell = "0x6cFC608940Ced73f09168201b84C9a7723515290"
//new below
//export var nftresell = "0xbcA52A57041361fE5Ed5e977e1b49154711712E4"
//export var nftresell = "0x868752c0e67119ab4A7f7d61B3C2fF165EbB6b58"
export var nftresell = "0xD4ce4C9548364365Bf04Bc66aE5EB17a3E0F242B"

//old export var marketcontract = "0x57dD187c999620900d3566d161e5d50F9a780d0e"
//new below
//export var marketcontract = "0x1b3920b74dB752ce9d1934C0e1B434B9D92cb494"
//latest working
/*
export var marketcontract = "0x4894225518C84797860E99046450b03197f4c39B"
*/



//old export var nftcreator = "0x9d98fACa62188Cc79b26bF806D8eca29206f8D20"
//new below
//export var nftcreator = "0x538D832D5aBB270402a4A42b0F2705B7768d6C0D"
/*
export var nftcreator = "0xD05a2820593ae6aC39e09C32562792863bc84033"
*/

//new variables
//export var marketcontract = "0x4F5188e1B0C8F5Ee2afDE683808912d4BC185159"
// export var marketcontract = "0x7A9478C6A0d79F25644897E37c9D2aa2983aa53E"
// export var hhmarket = "0x7A9478C6A0d79F25644897E37c9D2aa2983aa53E"
//export var hhnft = "0x07643f32fcF18d5163c335bBb6e71381528AB854"
// export var hhnft = "0xd1837Eaf4a8E1BA9c22950fCE227395D058216C9"
// export var nftcreator = "0xd1837Eaf4a8E1BA9c22950fCE227395D058216C9"
// export var hhnftcol = "0xC4543Fe1e298dB085f97146E66287f111D109cB2"
// export var nftcollection = "0xC4543Fe1e298dB085f97146E66287f111D109cB2"


//ONLY FOR TESTING PURPOSES: created 3/21/2023 at 1:27 PM
// export var marketcontract = "0x67ec957D2fa5d43B1c81d83E93850f9D858c0F92"
// export var hhmarket = "0x67ec957D2fa5d43B1c81d83E93850f9D858c0F92"
// export var hhnft = "0xbcA52A57041361fE5Ed5e977e1b49154711712E4"
// export var nftcreator = "0xbcA52A57041361fE5Ed5e977e1b49154711712E4"
// export var hhnftcol = "0x1b3920b74dB752ce9d1934C0e1B434B9D92cb494"
// export var nftcollection = "0x1b3920b74dB752ce9d1934C0e1B434B9D92cb494"

//ONLY FOR TESTING PURPOSES: created 3/25/2023 at 1:46 PM
export var marketcontract = "0x538D832D5aBB270402a4A42b0F2705B7768d6C0D"
export var hhmarket = "0x538D832D5aBB270402a4A42b0F2705B7768d6C0D"
//export var hhnft = "0xbcA52A57041361fE5Ed5e977e1b49154711712E4"
// export var nftcreator = "0xbcA52A57041361fE5Ed5e977e1b49154711712E4"
// export var hhnftcol = "0x868752c0e67119ab4A7f7d61B3C2fF165EbB6b58"
// export var nftcollection = "0x868752c0e67119ab4A7f7d61B3C2fF165EbB6b58"
//export var hhnft = "0xf8475815C21D03e781c4B1b525e0e4BBf2ff4AE3"
//export var hhnft = "0x00777211878464FEA75FDA38B34A90a7E98443cf" // for test purposes old version 1
// export var hhnft = "0xBE3FD1B9Ad7b20902F0B3Dea3Fc362978714266c" // for test purposes curr version
export var nftcreator = "0xf8475815C21D03e781c4B1b525e0e4BBf2ff4AE3"
export var hhnftcol = "0xf8475815C21D03e781c4B1b525e0e4BBf2ff4AE3"
export var nftcollection = "0xf8475815C21D03e781c4B1b525e0e4BBf2ff4AE3"
// export var owner_private_key = "e35e478c42e0baab3c839e5dea8e74bf550f573771ee1d52ed324b3f0a462b52" // contract owner private key

//AK 7/16/2023
export var hhnft = "0x520373bbD1C18177DB7691fBcC233F8A294fB606"
export var owner_private_key = "bcf2f78571fd827bf23f8345fa046316f6d683ad0e41a24b05ecac4c541ab402"


/*
Alchemy API Settings
*/
//export var alchemyapi = "https://eth-goerli.g.alchemy.com/v2/lAdfStqfGFWEey9XuCwx0xjKUaA9dAE9";
export var alchemyapi = "https://polygon-mumbai.g.alchemy.com/v2/cx_96UvN7TLuDCqPEGamsHPT-5gJR9jJ";

export var CONTRACT = "0xfcd78dcc6fd89605a676b6881becd8fc4b5e5d75";