import Footer from "./Footer";
import Navbar from "./Navbar";
import Features from "./abt/features";
import FeaturesBlocks from "./abt/features-blocks";
import Hero from "./abt/intro";
import '../css/about_page.css';
import Team from '../img/team.jpg';

export default function About() {
    return(
        <div className="w-full">
            <Navbar />
            <Hero />
            <div className="relative inline-flex mx-auto flex-col">
                <img className="md:max-w-none mx-auto px-5 rounded shadow-xl" src={Team} width={500} height="462" alt="Features bg" />
            </div>
            <FeaturesBlocks />
            <br />
            <br />
            {/* <div id="about_body">
                <div ClassName="about_section" id="about_box1">
                    <div ClassName="about_text_box">
                        <h1 className="about_h1 text-2xl font-bold">A simple and safe way to resell your tickets</h1>
                        <h3 className="text-xl font-bold">Start safely selling tickets in seconds.</h3>
                    </div>
                    <div ClassName="about_image_box">
                        <img src="../about_tickets.png" alt="tickets" ClassName="about_image" />
                    </div>
                </div>
                <div ClassName="about_section" id="about_box2">
                    <div ClassName="about_text_box">
                        <h1 className="about_h1 text-2xl font-bold">Buy, store, and sell tickets</h1>
                        <h3 className="text-xl font-bold">
                            A seamless onboarding equips users with a profile to
                            view all their tickets. Ownership is easily proven
                            and transactions can be tracked from anywhere.
                        </h3>
                    </div>
                    <div ClassName="about_image_box">
                        <img src="../about_ticket_booth.png" alt="ticket booth" ClassName="about_image" />
                    </div>
                </div>
                <div ClassName="about_section" id="about_box3">
                    <div ClassName="about_text_box">
                        <h1 className="about_h1 text-2xl font-bold">How do you sell a ticket?</h1>
                        <h3 className="text-xl font-bold">
                            Simply fill out the SCTicket sell form and submit it to make the
                            ticket available on the market for sale.
                        </h3>
                    </div>
                    <div ClassName="about_image_box">
                        <img src="../about_form.png" alt="form" ClassName="about_image" />
                    </div>
                </div>
                <div ClassName="about_section" id="about_box4">
                    <div ClassName="about_text_box">
                        <h1 className="about_h1 text-2xl font-bold">The team</h1>
                        <h3 className="text-xl font-bold">
                            We are a team of Web3/Blockchain enthusiasts with a passion
                            for developing technology for the future. With strong
                            determination, we want to create solutions and increase the
                            significance of blockchain technology in our community.
                        </h3>
                    </div>
                    <div ClassName="image_box">
                        <img src="../about_team.jpg" alt="team" ClassName="about_image" />
                    </div>
                </div>
            </div> */}
            <Footer />
        </div>
    );
}