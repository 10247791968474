import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import Web3Modal from "web3modal";
import axios from 'axios';
import { Grid, Card, Text, Button, Row, Container } from '@nextui-org/react';
import { nftresell } from '../engine/configuration';
import { Alchemy, Network } from "alchemy-sdk";
import confetti from 'canvas-confetti';
import 'sf-font';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Navbar from "./Navbar";
import Web3 from 'web3';
import Footer from './Footer';
import SCTicket from '../engine/SCTicket.json';
import JJ from '../img/jj.png';
import Joe from '../img/joe.png';
import Ticket from '../img/scticket.png';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/landing_page.css';
import { FaSistrix } from 'react-icons/fa';

const { ethers } = require("ethers");
const { BigNumber } = require("@ethersproject/bignumber")

require('dotenv').config();

const ALCHEMY_API = process.env.REACT_APP_ALCHEMY_API_KEY;
const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY;
const CONTRACT = process.env.REACT_APP_CONTRACT_ADDRESS;

export default function Home() {
  const [resellnft, getResellNfts] = useState([])
  const [availablenft, getAvailableNfts] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [searchParams, setSearchParams] = useState('')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  var alchemysettings = {
    apiKey: ALCHEMY_API,
    network: Network.MATIC_MAINNET
  }

  var web3ModalSettings = {
    network: "matic",
  }

  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  useEffect(() => {
    loadMarketTickets()
  }, [getResellNfts])

  const handleConfetti = () => {
    confetti();
  };

  async function loadMarketTickets() {
    const alchemy = new Alchemy(alchemysettings);
    // Connect to provider and invoke contract instance
    const provider = await alchemy.config.getProvider();
    // Get contract creator account
    const owner = new ethers.Wallet(PRIVATE_KEY, provider);
    let contract = new ethers.Contract(CONTRACT, SCTicket, owner);
    console.log(contract);
    // Display all tickets listed for sale
    const ticketsArray = []
    // CHANGE TO GETAVAILABLE NFT'S
    const marketTickets = await contract.fetchNfts();
    for (const ticket of marketTickets) {
      console.log(ticket);
      // convert BigNumber to regular
      let token_id = BigNumber.from(ticket.id).toNumber();
      let name = ticket.name;
      let price = BigNumber.from(ticket.price).toNumber();
      let date = ticket.date;
      let location = ticket.location;
      let seat = ticket.seat;
      //let image = ticket.image;

      // const response = alchemy.nft.getNftMetadata(CONTRACT_ADDRESS, token_id)
      // await new Promise((r) => setTimeout(r, 1000));
      // console.log(response)
      // console.log((await response).rawMetadata)
      // let image = (await response).rawMetadata.image;
      // COMMENTED OUT BC OF ERROR OCCURRING
      // if (image) {
      // image = image.replace("https://gateway.pinata.cloud/", "https://ipfs.io/");
      // }
      let ticketData = {
        tokenId: token_id,
        name: name,
        price: price,
        date: date,
        seat: seat,
        location: location,
        //image: image,
      };
      console.log(ticketData)
      ticketsArray.push(ticketData);
    }
    await new Promise(r => setTimeout(r, 3000));
    getResellNfts(ticketsArray);
    getAvailableNfts(ticketsArray);
  }

  async function buyMarketNfts(nft) {
    const price_wei = nft.price.toString() + "000000000000000000";
    const price = ethers.utils.parseUnits(price_wei, 'wei');
    // Load contract and initiate ticket transfer
    const web3Modal = new Web3Modal(web3ModalSettings)
    const connection = await web3Modal.connect()
    // WORKING: Get network provider thru Web3Provider
    const ethersProvider = new ethers.providers.Web3Provider(connection)
    // WORKING: Prompt user for account connections
    await ethersProvider.send("eth_requestAccounts", []);
    const signer = ethersProvider.getSigner();
    const balance = await signer.getBalance();
    console.log(balance);
    if (balance < price) {
      setErrorMsg("You don't have enough balance to complete this transaction.");
      setError(true);
      console.log(balance);
      console.log("Not enough balance for the transaction")
      return;
    }
    const contract = new ethers.Contract(CONTRACT, SCTicket, signer)
    const transaction = await contract.buyTicket(nft.tokenId, {
      value: price,
      gasLimit: 30_000_000,
    })
    await transaction.wait()
    setError(false);
    loadMarketTickets();
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 1,
      slidesToSlide: 1 
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(document.getElementById('search_bar').value);
    setSearchParams(document.getElementById('search_bar').value);
  }

  return (
    <div>
    <Navbar></Navbar>

    {/* search field test */}
    <form id="search_field" onSubmit={handleSubmit}>
        <div id="search_section">
            <span className="img_button" id="magnifying_glass"><FaSistrix/></span>
            <input type="text" id="search_bar" name="search_bar" placeholder="Search for concerts and events" value={searchInput} onChange={(e) => setSearchInput(e.target.value)}/>
            <button className="bg-logo-color-green text-white rounded-md px-5 hover:bg-logo-color-yellow" type="submit">Search</button>
        </div>
    </form>

    <br />

    <div id="spotlight">
        <Slider className="slideshows" id="slideshow" {...sliderSettings}>
            <div className="pic1">
                <img src={Joe} />
                <h1 className="spot_title">Joe Hisaishi</h1>
                <h3 className="details">Los Angeles, CA</h3>
                <h3 className="details">June 28, 2023</h3>
                <h3 className="details">Hollywood Bowl</h3>
            </div>
            <div className="pic2">
                <img src={JJ} />
                <h1 className="spot_title">JJ Lin</h1>
                <h3 className="details">San Francisco, CA</h3>
                <h3 className="details">October 11, 2023</h3>
                <h3 className="details">Chase Arena</h3>
            </div>
        </Slider>
    </div>

    <br />
    <br />

    <div className="text-red-500 font-bold">{error ? errorMsg : ""}</div>
      
      <Container sm="true">
        <div className="flex flex-col mb-10 place-items-center md:text-2xl font-bold text-white">
        NFTs in Marketplace
        </div>
        <Grid.Container gap={1} justify="flex-start">
           {resellnft.filter(ticket => {
              if (searchParams === '') {
                return ticket;
              } else if (ticket.name.toLowerCase().includes(searchInput.toLowerCase())) {
                return ticket;
          }}).slice(0, 9).map((nft, id) => {
            return (
              <Grid gap={4} key={id} xs={4}>
                <Card
                  key={id}
                  style={{ boxShadow: "0px 0px 5px #ffffff" }}
                  variant="bordered"
                  isHoverable
                  css={{ mw: "300px", p: "$2"}}
                >
                  <Card.Body css={{ p: "$6" }}>
                    <Card.Image
                      className="object-cover"
                      style={{ width: "270px", height: "300px", borderRadius: "3%", marginTop: "3px" }}
                      src={Ticket}
                    />
                    <Text
                    style={{
                      color: "black",
                      fontWeight: "bolder",
                      fontFamily: "SF Pro Display",
                      fontWeight: "400",
                      fontSize: "18px",
                      marginTop: "11px",
                      letterSpacing: "0.03em",
                      textAlign: "center",
                    }}
                  >
                    {nft.name}
                  </Text>
                    <Row wrap="wrap" justify="space-between" align="center">
                      <Text
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                        fontFamily: "SF Pro Display",
                        fontWeight: "400",
                        fontSize: "16px",
                        letterSpacing: "0.03em",
                        marginBottom: "9px",
                      }}
                      >{nft.date}<br />
                      {nft.seat}<br />
                      </Text>
                    </Row>
                    <Text style={{ fontSize: "20px", marginBottom: "9px",}}>
                      Price: {nft.price} MATIC
                    </Text>
                    <Text style={{ fontSize: "10px", marginBottom: "9px",}}>
                      *By clicking "buy" you agree to the <Link to="/useragreement">user agreement</Link>
                    </Text>
                    <Row align="center" justify-content="center">
                    <button className="bg-logo-color-green hover:bg-logo-color-yellow md:text-md text-white font-bold py-2 px-10 rounded-md w-full"
                      color="gradient"
                      style={{ fontSize: "20px" }}
                      onClick={() => handleConfetti(buyMarketNfts(nft))}
                    >
                    Buy
                    </button>
                  </Row>
                  </Card.Body>
                </Card>
              </Grid>
            )
          })
        }
        </Grid.Container>
        <br />
        <br />
      </Container>

      <Footer />
    </div>
  );
}