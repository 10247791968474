import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useNavigate
} from "react-router-dom";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import '../css/landing_page.css';
import '../slick/slick/slick.css'; // slick stylesheet
import '../slick/slick/slick-theme.css'; // slick stylesheet
import Logo from '../img/logo.png';
// import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
// import Icon from 'react-native-vector-icons/AntDesign';
import { Web3Auth } from "@web3auth/modal";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal, Web3Button, useWeb3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig, useAccount, useDisconnect, useConnect } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

require('dotenv').config();
const { ethers } = require("ethers");

// const clientId = "BHSwfpedAUFldpfl1aM_Jze1YLhiYCAqYnxiojiKRLlYScWV1GN9ZVFNauCAXNKKnnZ_QjsnGo2u2jboEDscAGw";
// const clientId = "BD9PJ8bnnJKkiSq3PCh5XHwPi4aZppj9P1cq8aV3sdIQa-UjrnQPZGQyX4O0XCORiLcMhIQCy6CcI0Xp56vS7lk";
const clientId = "BDUJ6lvESgJLqWlB23rgUfJLFw0y1miL0kh9AF4dcNSIyjYDstAMWAcU3wz0sp4MRRxzD1vre8ienNxzrRsaYX4";

const projectId = process.env.REACT_APP_PROJECT_ID;
const rpc = process.env.REACT_APP_ALCHEMY_API_URL;
const chains = [arbitrum, mainnet, polygon];

function Navbar(){
  const [ethereumClient, setEthereumClient] = useState(null);
  const { open, close } = useWeb3Modal();
  const { connect } = useConnect();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();

  const [ web3auth, setWeb3Auth ] = useState(null);

  const web3authSettings = {
    clientId: clientId, // get it from Web3Auth Dashboard
    web3AuthNetwork: "cyan",
    chainConfig: {
      chainNamespace: "eip155",
      chainId: "0x89", // hex of 137, polygon mainnet
      rpcTarget: rpc,
      // Avoid using public rpcTarget in production.
      // Use services like Infura, Quicknode etc
      displayName: "Polygon Mainnet",
      blockExplorer: "https://polygonscan.com",
      ticker: "MATIC",
      tickerName: "Matic",
    },
  }

  useEffect(() => {
    const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
    const wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId, chains }),
      publicClient
    });
    const client = new EthereumClient(wagmiConfig, chains);
    setEthereumClient(client);

    // ***
    const web3 = new Web3Auth(web3authSettings);
    setWeb3Auth(web3);

  }, []);

  const handleLogin = async() => {
    open();
    connect();

    // await web3auth.initModal();
    // console.log(web3auth);
    // const web3authProvider = await web3auth.connect();
    // console.log(web3authProvider);
    // const provider = new ethers.providers.Web3Provider(web3authProvider); // web3auth.provider
    // console.log(provider);
  }
  
  const handleLogout = async() => {
    close(); // logs out of wallet connect
    console.log("closed");
    disconnect(); // logs out of wagmi
    console.log("disconnected");
  }

  const unloggedInView = (
    <ul id="nav_form">
        {/* <div id="search_section">
            <span className="img_button" id="magnifying_glass"><FaSistrix/></span>
            <input type="text" id="search_bar" name="search_bar" placeholder="Search for concerts and events" />
        </div> */}
        <li className="text_button" onClick={handleLogin}>Sign In</li>
        <li className="text_button"><Link to="/">Upcoming</Link></li>
        <li className="text_button"><Link to="/about">About</Link></li>
        <span className="img_button"><i className="fa-solid fa-user"></i></span>
        <span className="img_button"><i className="fa-solid fa-cart-shopping"></i></span>
    </ul>
  );

    const loggedInView = (
      // <div className="container">
          <form id="nav_form">
            <button className="text_button" onClick={handleLogout}>Sign out</button>
            <button className="text_button"><Link to="/profile">Profile</Link></button>
            <button className="text_button"><Link to="/sellNFT">Sell</Link></button>
            <button className="text_button"><Link to="/">Upcoming</Link></button>
            <button className="text_button"><Link to="/about">About</Link></button>
            {/* <span className="img_button"><i className="fa-solid fa-user"></i></span>
            <span className="img_button"><i className="fa-solid fa-cart-shopping"></i></span> */}
        </form>
    );

    return(
      <div id="nav_bar">
      <img id="logo" src={Logo} alt="logo" />
      <section id="logo_title"><b>SCTicket</b></section>
      <div className="vertical_line" id="nav_vert"></div>
        {isConnected ? loggedInView : unloggedInView}
    </div>
    );
  }

  export default Navbar;