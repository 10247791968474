import Navbar from "./Navbar";
import axios from "axios";
import { useState, useEffect } from "react";
import Web3Modal from "web3modal";
import { Card, Button, Col, Row, Container, Text, Grid, Input } from '@nextui-org/react';
import 'sf-font';
import Web3 from 'web3';
import { nftcollection } from '../engine/configuration';
import { Alchemy, Network } from "alchemy-sdk";
import Footer from './Footer';
import SCTicket from '../engine/SCTicket.json';
import { saveAs } from 'file-saver';

require('dotenv').config();

const { ethers } = require("ethers");
const { BigNumber } = require("@ethersproject/bignumber")

const ALCHEMY_API = process.env.REACT_APP_ALCHEMY_API_KEY;
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

export default function Sell() {
    const [nftsPurchased, getNftsPurchased] = useState([])
    const [nftsSelling, getNftsSelling] = useState([])
    const [loaded, awaitLoading] = useState('not-loaded')
    const [resalePrice, updateresalePrice] = useState({ price: ''})
    const [description, setDescription] = useState();
  
    var alchemysettings = {
      apiKey: ALCHEMY_API,
      network: Network.MATIC_MAINNET
    }

    var web3ModalSettings = {
      network: "matic",
    }
  
    var account = null;
    var web3 = null;
  
      // version 1
      // useEffect(() => {
      //   getAlchemyNFTs()
      // }, [getNfts])

      // version 2
      useEffect(() => {
        displayTickets()
      }, [getNftsPurchased])
    

    const downloadImage = async(image_url, image_name) => {
      saveAs(image_url, image_name+'.jpg') // Put your image url here.
    }

    async function displayTickets() {
      //connect to Alchemy
      const alchemy = new Alchemy(alchemysettings);
      // connect to provider
      const web3Modal = new Web3Modal(web3ModalSettings);
      const connection = await web3Modal.connect()
      // Get network provider thru Web3Provider
      const ethersProvider = new ethers.providers.Web3Provider(connection)
       // Prompt user for account connections
       await ethersProvider.send("eth_requestAccounts", []);
       const signer = ethersProvider.getSigner();
       // Set account address
       document.getElementById("wallet-address").textContent = await signer.getAddress();

       // Invoke smart contract
       let contract = new ethers.Contract(CONTRACT_ADDRESS, SCTicket, signer)
       // Call the function to retrieve the owner's tickets
       let purchased_tickets = await contract.getMyPurchasedNfts();
       let selling_tickets = await contract.getMyMarketNfts();
       console.log(purchased_tickets)

       var purchasedTicketsArray = []
       var sellingTicketsArray = []

       for(const ticket of purchased_tickets) {
        // convert BigNumber to regular
        let token_id = BigNumber.from(ticket.id).toNumber();
        let name = ticket.name;
        let price = BigNumber.from(ticket.price).toNumber();
        let date = ticket.date;
        let seat = ticket.seat;
        let location = ticket.location;
        let image = ticket.image;

        // const response = alchemy.nft.getNftMetadata(hhnft, token_id)
        // await new Promise((r) => setTimeout(r, 1000));
        // console.log(response)
        // console.log((await response).rawMetadata)
        // let image = (await response).rawMetadata.image;
        if(image) {
          image = image.replace("https://gateway.pinata.cloud/", "https://ipfs.io/");
        }
        // if (response.rawMetadata.image === undefined) {
        //   console.log("No image found");
        // } else {
        //   image = response.rawMetadata.image
        //   image = image.replace("ipfs://", "https://ipfs.io/ipfs/");
        // }
        // let desc = (await response).rawMetadata.date;
        //let t_price = (await response).rawMetadata.price;
        let ticketData = {
          tokenId: token_id,
          name: name,
          price: price,
          date: date,
          seat: seat,
          location: location,
          image: image,
          download_name: name + ".jpg"
        };
        console.log(ticketData)
        purchasedTicketsArray.push(ticketData);
      }

      for(const ticket of selling_tickets) {
        // convert BigNumber to regular
        let token_id = BigNumber.from(ticket.id).toNumber();
        let name = ticket.name;
        let price = BigNumber.from(ticket.price).toNumber();
        let date = ticket.date;
        let seat = ticket.seat;
        let location = ticket.location;
        let image = ticket.image;

        // const response = alchemy.nft.getNftMetadata(hhnft, token_id)
        // await new Promise((r) => setTimeout(r, 1000));
        // console.log(response)
        // console.log((await response).rawMetadata)
        // let image = (await response).rawMetadata.image;
        if(image) {
          image = image.replace("https://gateway.pinata.cloud/", "https://ipfs.io/");
        }
        // if (response.rawMetadata.image === undefined) {
        //   console.log("No image found");
        // } else {
        //   image = response.rawMetadata.image
        //   image = image.replace("ipfs://", "https://ipfs.io/ipfs/");
        // }
        // let desc = (await response).rawMetadata.date;
        //let t_price = (await response).rawMetadata.price;
        let ticketData = {
          tokenId: token_id,
          name: name,
          price: price,
          date: date,
          seat: seat,
          location: location,
          image: image,
          download_name: name + ".jpg"
        };
        console.log(ticketData)
        sellingTicketsArray.push(ticketData);
      }

      await new Promise((r) => setTimeout(r, 1000));
      getNftsPurchased(purchasedTicketsArray);
      getNftsSelling(sellingTicketsArray);
      awaitLoading("loaded");
    }

      async function getAlchemyNFTs() {
        const alchemy = new Alchemy(alchemysettings);
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        web3 = new Web3(connection);
        await connection.send("eth_requestAccounts");
        var accounts = await web3.eth.getAccounts();
        account = accounts[0];
        var itemArray = [];
        document.getElementById("wallet-address").textContent = account;
        const mintedNfts = await alchemy.nft.getNftsForOwner(account);
        console.log(mintedNfts)
        for (const nft of mintedNfts.ownedNfts) {
          let contractaddr = nft.contract.address;
          let token = nft.tokenId;
          let collection = nftcollection.toLowerCase();
         // if (contractaddr === collection) {
          const response = await alchemy.nft.getNftMetadata(
            contractaddr, 
            token)
            await new Promise((r) => setTimeout(r, 1000));
            let name = response.title;
            let imagePath = response.rawMetadata.image;
            if (imagePath === undefined) {
              console.log("No image found");
            } else {
              let img = imagePath.replace("ipfs://", "https://ipfs.io/ipfs/");
              let desc = response.description;
              alchemy.nft.getContractMetadata(contractaddr).then((result) => {
                let nftdata = {
                  name: name,
                  img: img,
                  tokenId: token,
                  desc: desc,
                  contract: result.name,
                };
                itemArray.push(nftdata);
              });
            }
          }
        await new Promise((r) => setTimeout(r, 1000));
        getNftsPurchased(itemArray);
        awaitLoading("loaded");
    }

  if (loaded === "loaded" && !nftsPurchased.length)
  return (
    <div className="profileClass" style={{"min-height":"100vh"}}>
    <Navbar></Navbar>
            <div className="flex text-center flex-col mt-11 md:text-2xl text-black-500">
                <div className="mb-5">
                    <h2 className="font-bold">Tickets for Wallet</h2>  
                    <h3 id="wallet-address"></h3>
                </div>
            {/* <div class="object-center">
              <button class="bg-red-600 hover:bg-green-500 md:text-2xl text-white font-bold py-10 px-10 rounded-full">
              Retrieve NFTs
              </button> 
            </div> */}
     <Text h4>No NFT's Found on Wallet</Text>
     </div>
     </div>
  );

return (
    <div>
    <Navbar></Navbar>
            <div className="flex text-center flex-col mt-11 md:text-2xl text-white">
                <div className="mb-5">
                    <h2 className="font-bold">Wallet</h2>  
                    <h3 id="wallet-address"></h3>
                </div>
            {/* <div class="object-center">
              <button class="bg-blue-500 hover:bg-green-500 md:text-xl text-white font-medium py-3 px-4 rounded-md">
              Retrieve NFTs
              </button> 
            </div> */}
            </div>
      <h2 className="text-2xl font-bold">My Purchased Tickets</h2>
      <Row>
      <Container sm="true">
        <Grid.Container gap={3} justify="flex-start">
          {nftsPurchased.map((nft, id) => { 

          //   async function executeRelist() {
          //     const { price } = resalePrice;
          //     if (!price) return;
          //     try {
          //       relistNFT();
          //     } catch (error) {
          //       console.log("Transaction Failed", error);
          //     }
          //   }
          //   async function relistNFT() {
          //     const web3Modal = new Web3Modal()
          //     const connection = await web3Modal.connect()
          //     //const provider = new ethers.providers.Web3Provider(connection)
          //     const provider = new AlchemyProvider(connection)
          //     const signer = provider.getSigner()
          //     const price = ethers.parseUnits(resalePrice.price, 'ether')
          //     const contractnft = new ethers.Contract(nftcollection, NFTCollection, signer);
          //     await contractnft.setApprovalForAll(nftresell, true);
          //     let contract = new ethers.Contract(nftresell, Resell, signer)
          //     let listingFee = await contract.getListingFee()
          //     listingFee = listingFee.toString()
          //     let transaction = await contract.listSale(nft.tokenId, price, { value: listingFee, gasLimit: 3e7 })
          //     await transaction.wait()
          //     //router.push('/')
          // }
            /*async function relistNFT() {
              const web3Modal = new Web3Modal()
              const connection = await web3Modal.connect()
              const provider = new ethers.providers.Web3Provider(connection);
              const signer = provider.getSigner();
              const user = (await signer.getAddress()).toString()
              const price = ethers.utils.parseUnits(
                resalePrice.price,
                "ether"
              );
              const contractnft = new ethers.Contract(
                nftcollection,
                NFTCollection,
                signer
              )
              /****SOMETHING WRONG WITH BELOW FUNCTION!!!!!! 
              const checkapproved = await contractnft.isApprovedForAll(user, nftresell, { gasLimit: 3e7 })
              if (checkapproved === true ){
                let contract = new ethers.Contract(nftresell, Resell, signer); 
                let listingFee = await contract.getListingFee();
                listingFee = listingFee.toString();
                let transaction = await contract.listSale(nft.tokenId, price, {
                  value: listingFee,
                  gasLimit: 3e7
                })
                await transaction.wait();
                getAlchemyNFTs();
              }
              else {
                let approval = await contractnft.setApprovalForAll(nftresell, true);
                await approval.wait()
                let contract = new ethers.Contract(nftresell, Resell, signer);
                let listingFee = await contract.getListingFee();
                listingFee = listingFee.toString();
                let transaction = await contract.listSale(nft.tokenId, price, {
                  value: listingFee,
                  gasLimit: 3e7
                });
                await transaction.wait();
                getAlchemyNFTs();
              }
          }*/
              return (
                <Grid gap={4} key={id} xs={4}>
                <Card
                  key={id}
                  style={{ boxShadow: "0px 0px 5px #ffffff" }}
                  variant="bordered"
                  isHoverable
                  css={{ mw: "300px", p: "$2"}}
                >
                  <Card.Body css={{ p: "$6" }}>
                    <Card.Image
                      className="object-cover"
                      style={{ width: "270px", height: "300px", borderRadius: "3%", marginTop: "3px" }}
                      src={nft.image}
                    />
                    <Text
                    style={{
                      color: "black",
                      fontWeight: "bolder",
                      fontFamily: "SF Pro Display",
                      fontWeight: "400",
                      fontSize: "18px",
                      marginTop: "11px",
                      letterSpacing: "0.03em",
                      textAlign: "center"
                    }}
                  >
                    {nft.name}
                  </Text>
                    <Row wrap="wrap" justify="space-between" align="center">
                      <Text
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                        fontFamily: "SF Pro Display",
                        fontWeight: "400",
                        fontSize: "16px",
                        letterSpacing: "0.03em",
                        marginBottom: "9px",
                      }}
                      >{nft.date}<br />
                      {nft.seat}<br />
                      </Text>
                    </Row>
                    <Text style={{ fontSize: "20px", marginBottom: "9px",}}>
                      Price: {nft.price} MATIC
                    </Text>
                    <button className="bg-logo-color-green text-center hover:bg-logo-color-yellow md:text-md text-white font-bold py-2 px-10 rounded-md w-full"
                      color="gradient"
                      style={{ fontSize: "20px" }}
                      onClick={() => downloadImage(nft.image, nft.name)}
                    >
                    Download
                    </button>
                    <Row align="center" justify-content="center">
                  </Row>
                  </Card.Body>
                </Card>
              </Grid>
              );
            })}
        </Grid.Container>
        </Container>
      </Row>
      <br />
      <h2 className="text-2xl font-bold">My Tickets for Sale</h2>
      <Row>
      <Container sm="true">
        <Grid.Container gap={3} justify="flex-start">
          {nftsSelling.map((nft, id) => { 
              return (
                <Grid gap={4} key={id} xs={4}>
                <Card
                  key={id}
                  style={{ boxShadow: "0px 0px 5px #ffffff" }}
                  variant="bordered"
                  isHoverable
                  css={{ mw: "300px", p: "$2"}}
                >
                  <Card.Body css={{ p: "$6" }}>
                    <Card.Image
                      className="object-cover"
                      style={{ width: "270px", height: "300px", borderRadius: "3%", marginTop: "3px" }}
                      src={nft.image}
                    />
                    <Text
                    style={{
                      color: "black",
                      fontWeight: "bolder",
                      fontFamily: "SF Pro Display",
                      fontWeight: "400",
                      fontSize: "18px",
                      marginTop: "11px",
                      letterSpacing: "0.03em",
                      textAlign: "center"
                    }}
                  >
                    {nft.name}
                  </Text>
                    <Row wrap="wrap" justify="space-between" align="center">
                      <Text
                      style={{
                        color: "gray",
                        fontWeight: "bold",
                        fontFamily: "SF Pro Display",
                        fontWeight: "400",
                        fontSize: "16px",
                        letterSpacing: "0.03em",
                        marginBottom: "9px",
                      }}
                      >{nft.date}<br />
                      {nft.seat}<br />
                      </Text>
                    </Row>
                    <Text style={{ fontSize: "20px", marginBottom: "9px",}}>
                      Price: {nft.price} MATIC
                    </Text>
                    <Row align="center" justify-content="center">
                  </Row>
                  </Card.Body>
                </Card>
              </Grid>
              );
            })}
        </Grid.Container>
        </Container>
      </Row>
      <Footer />
  </div>
)
};