import { useEffect, useState } from 'react';
import Web3Modal from "web3modal";
import SCTicket from '../engine/SCTicket.json';
import 'sf-font';
import Navbar from "./Navbar";
import Footer from "./Footer";
import { uploadFileToIPFS, uploadJSONToIPFS } from "../utils/pinata";
import { Alchemy, Network } from "alchemy-sdk";
import '../css/user_form.css';
import { useAccount } from 'wagmi'
import { Web3Auth } from "@web3auth/modal";

const { ethers } = require("ethers");
require('dotenv').config();

const ALCHEMY_API = process.env.REACT_APP_ALCHEMY_API_KEY;
const PRIVATE_KEY = process.env.REACT_APP_PRIVATE_KEY;
const CONTRACT = process.env.REACT_APP_CONTRACT_ADDRESS;
const clientId = "BDUJ6lvESgJLqWlB23rgUfJLFw0y1miL0kh9AF4dcNSIyjYDstAMWAcU3wz0sp4MRRxzD1vre8ienNxzrRsaYX4";
const rpc = process.env.REACT_APP_ALCHEMY_API_URL;

export default function SellNFT() {
    const [formInput, updateFormInput] = useState({ price: '', name: '', description: ''})
    const [name, setName] = useState('')
    const [date, setDate] = useState('')
    const [seat, setSeat] = useState('')
    const [location, setLocation] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')
    const [img, setImage] = useState('')
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const { isConnected } = useAccount();

    const [ web3auth, setWeb3Auth ] = useState(null);

    var alchemysettings = {
        apiKey: ALCHEMY_API,
        network: Network.MATIC_MAINNET
    }

    var web3ModalSettings = {
        network: "matic",
    }

    const web3authSettings = {
        clientId: clientId, // get it from Web3Auth Dashboard
        web3AuthNetwork: "cyan",
        chainConfig: {
          chainNamespace: "eip155",
          chainId: "0x89", // hex of 137, polygon mainnet
          rpcTarget: rpc,
          // Avoid using public rpcTarget in production.
          // Use services like Infura, Quicknode etc
          displayName: "Polygon Mainnet",
          blockExplorer: "https://polygonscan.com",
          ticker: "MATIC",
          tickerName: "Matic",
        },
      }

    useEffect(() => {
        const web3 = new Web3Auth(web3authSettings);
        setWeb3Auth(web3);
    
      }, []);
    
    // Once the form is submitted,
    // calls createMarket() function
    const handleSubmit = (event) => {
        event.preventDefault();
        createMarket();
    };

    // Creates SCTicket by invoking the smart contract
    async function createMarket() {
        if(!isConnected) {
            console.log("User is not logged in");
            setErrorMsg("Please connect with your wallet to sell the ticket!");
            setError(true);
            return;
        }
        //console.log(`Inside crate market function`);
        //const { name, description, price } = formInput
        // Form parameters: Image, Name, Date, Seat, Location, Price
        if (!name || !date || !location || !price || !img) {
            console.log(`Parameters are missing in the form.`);
            return
        }
        // let date = '2/5/2023' // for testing
        // let seat = 'Row 34 seat 6' // for testing
        let fileUrl = ''
        // Upload image to Pinata
        try {
            //upload the file to IPFS
            const response = await uploadFileToIPFS(img);
            if(response.success === true) {
                console.log("Uploaded image to Pinata: ", response.pinataURL)
                fileUrl = response.pinataURL
            }
            //return; // for testing the ipfs image upload
        }
        catch(e) {
            console.log("Error during image upload to Pinata", e);
        }

        console.log(fileUrl)

        // Upload ticket metadata to Pinata
        const nftJSON = {
            name, 
            price,
            date,
            seat,
            location,
            image: fileUrl
        }
        //const { encryptedString, encryptedSymmetricKey } = await Navbar.encryptText(description);
        // Convert blob to base64 to pass as a string to Solidity
        const blobToBase64 = (blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            });
        };

        if(!nftJSON.image) return
       //const encryptedDescriptionString = await blobToBase64(encryptedString);
       var ticketUrl = ''
        try {
            //const added = await client.add(data)
            const response = await uploadJSONToIPFS(nftJSON);
        //const url = `https://ipfs.infura.io/ipfs/${added.path}`
            if(response.success === true){
                console.log("Uploaded JSON to Pinata: ", response.pinataURL)
                ticketUrl = response.pinataURL;
            } 
            //return; //for testing purposes
        } catch(e) {
            console.log("Failed to upload metadata to Pinata ", e)
        }

        //const url = response.pinataURL;

        await web3auth.initModal();
        console.log(web3auth);
        const web3authProvider = await web3auth.connect();
        console.log(web3authProvider);
        const test_provider = new ethers.providers.Web3Provider(web3authProvider); // web3auth.provider

        // Set up network connection
        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();
        console.log(`Inside create market function`);
        // NOT WORKING: get provider thru Alchemy --> apparently not working
        const alchemy = new Alchemy(alchemysettings);
        const provider = new ethers.providers.Web3Provider(connection);
        console.log(provider);
        // Get signer using wallet Private key --> NOT AN OPTION b/c don't know the user's private key
        const account = new ethers.Wallet(PRIVATE_KEY, provider)
        console.log(account);
        // WORKING: Get network provider thru Web3Provider
        const ethersProvider = new ethers.providers.Web3Provider(connection)
        // WORKING: Prompt user for account connections
        await ethersProvider.send("eth_requestAccounts", []);
        // const signer = ethersProvider.getSigner();
        const signer = await provider.getSigner();
        const signer_chain = await signer.getChainId();
        if(signer_chain != 137) {
            console.log(signer_chain);
            console.log("Signer is not connected to Polygon.");
            setErrorMsg("Please connect your Metamask to Polygon blockchain!");
            setError(true);
            return;
        }
        console.log("Account:", await signer.getAddress());
 
        // Invoke smart contract
        let contract = new ethers.Contract(CONTRACT, SCTicket, signer)
        console.log(contract);
        // Mint SCTicket by calling the smart contract function
        let transaction = await contract.createTicket(name, price, date, location, seat, fileUrl, ticketUrl, {
            //gasLimit: 30_000_000,
            //maxPriorityFeePerGas: 100_000_000_000, 
            //maxFeePerGas: 100_000_000_000
        });
        // old contract call
        //let transaction = await contract.mintNFT("venvunetjnv", {gasLimit: 6_000_000,maxPriorityFeePerGas: 3_000_000, maxFeePerGas: 3_000_000});
        // Print transaction hash
        console.log(transaction);
        console.log(`Transaction Hash: ${transaction.hash}`);
        setError(false);
        // let tx = await transaction.wait()
        // let event = tx.events[0]
        // let value = event.args[2]
        // let tokenId = value.toNumber()
        //const price = ethers.parseUnits(formInput.price, 'ether')
        // 2nd old contract --> likely not needed
        // contract = new ethers.Contract(hhmarket, Market, account)
        // let listingFee = await contract.listingFee()
        // listingFee = listingFee.toString()
        // let transaction = await contract.createVaultItem(hhnft, tokenId, price, { value: listingFee })
        // await transaction.wait()
        // window.location.replace("/profile")

            // createNFT(response.pinataURL)
        // } catch (error) {
        //     console.log('Error uploading file: ', error)
        // }
    }

  return (
    <div>
        <Navbar></Navbar>
        <div className="text-red-500 font-bold">{error ? errorMsg : ""}</div>
        <div className="w-1/2 mx-auto">
            <form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
            <div id="header">
                <br />
                <h1 className="font-bold text-xl text-center">Sell My Ticket</h1>
                <h5><span className="asterisk">*</span> Indicates Required Fields</h5>
            {/* </div>
            <div className="form_field"> */}
            <br/>
                <h3 className="field_title font-bold">Image of the ticket (QR code, barcode) <span className="asterisk">*</span></h3>
                <p>File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max size: 100 MB</p>
                <label for="userfile" className="custom_upload">
                    <i className="fa-solid fa-upload icon"></i>
                </label>
                <input required type={"file"} onChange={(event) => setImage(event.target.files[0])} id="userfile" name="userfile"></input>
            {/* </div>
            <div className="form_field"> */}
                <br />
                <h3 className="field_title font-bold">Event name <span className="asterisk">*</span></h3>
                <input required type="text" onChange={(event) => setName(event.target.value)} value={name} id="filename" name="filename" placeholder="Name of the event"></input>
            {/* </div>
            <div className="form_field"> */}
            <br />
            <br />
                <h3 className="field_title font-bold">Date <span className="asterisk">*</span></h3>
                <input required type="date" onChange={(event) => setDate(event.target.value)} value={date} id="eventdate" name="eventdate" placeholder="Date of event"></input>
            {/* </div>
            <div className="form_field"> */}
            <br />
            <br />
                <h3 className="field_title font-bold">Seat </h3>
                <input type="text" onChange={(event) => setSeat(event.target.value)} value={seat} id="eventseat" name="eventseat" placeholder="Seat number"></input>
            {/* </div>
            <div className="form_field"> */}
            <br />
            <br />
                <h3 className="field_title font-bold">Location <span className="asterisk">*</span></h3>
                <input required type="text" onChange={(event) => setLocation(event.target.value)} value={location} id="location" name="location" placeholder="Address of the event"></input>
            {/* </div>
            <div className="form_field"> */}
            <br />
            <br />
                <h3 className="field_title font-bold">Price <span className="asterisk">*</span></h3>
                <input required type="number" onChange={(event) => setPrice(event.target.value)} value={price} id="fileprice" name="fileprice" placeholder="Selling price of ticket"></input>
            {/* </div>
            <div className="form_field"> */}
            <br />
            <br />
                {/* <h3 className="field_title">Description</h3>
                <p>The description will be included on the item's detail page underneath its image. Markdown syntax is supported.</p>
                <textarea id="filedesc" name="filedesc" placeholder="Provide a detailed description of your item."></textarea> */}
            {/* </div>
            <div className="form_field"> */}
            <br />
            </div>
            <input type="submit" className="button font-bold bg-logo-color-green hover:cursor-pointer hover:bg-logo-color-yellow"></input>
            <br></br>
        </form>
        </div>
        <br />
        <Footer />
    </div>
        // <div className="flex flex-col place-items-center mt-10" id="nftForm">
        //     <form className="bg-white shadow-md rounded px-8 pt-4 pb-8 mb-4" onSubmit={handleSubmit}>
        //     <h3 className="text-center font-bold text-purple-500 mb-8">Upload your Ticket to the Marketplace</h3>
        //         <div className="mb-4">
        //             <label className="block text-purple-500 text-sm font-bold mb-2" htmlFor="name">Event Name</label>
        //             <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Name" onChange={(event) => setName(event.target.value)} value={name}></input>
        //         </div>
        //         <div className="mb-6">
        //             <label className="block text-purple-500 text-sm font-bold mb-2" htmlFor="description">Digital Ticket Link</label>
        //             <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" cols="40" rows="5" id="description" type="text" placeholder="Link" value={description} onChange={(event) => setDescription(event.target.value)}></textarea>
        //         </div>
        //         {/* <div className="mb-6">
        //             <label className="block text-purple-500 text-sm font-bold mb-2" htmlFor="eventDate">Event Date</label>
        //             <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" placeholder="06 12 2023" step="0.01" value={formInput.date} onChange={e => updateFormInput({...formInput, date: e.target.value})}></input>
        //         </div>
        //         <div className="mb-6">
        //             <label className="block text-purple-500 text-sm font-bold mb-2" htmlFor="eventDate">Event Address</label>
        //             <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="1111 S Figueroa St, Los Angeles, CA 90015" step="0.01" value={formInput.address} onChange={e => updateFormInput({...formInput, address: e.target.value})}></input>
        //         </div>
        //         <div className="mb-6">
        //             <label className="block text-purple-500 text-sm font-bold mb-2" htmlFor="eventDate">Seat Details</label>
        //             <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Col A Row 3 Seat 4" step="0.01" value={formInput.seat} onChange={e => updateFormInput({...formInput, seat: e.target.value})}></input>
        //         </div> */}
        //         <div className="mb-6">
        //             <label className="block text-purple-500 text-sm font-bold mb-2" htmlFor="price">Price (in MATIC)</label>
        //             <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" placeholder="0.01" step="0.01" value={price} onChange={(event) => setPrice(event.target.value)}></input>
        //         </div>
        //         <div>
        //             <label className="block text-purple-500 text-sm font-bold mb-2" htmlFor="image">Upload Image</label>
        //             <input type={"file"} onChange={(event) => setImage(event.target.files[0])}></input>
        //             {/* <input type={"file"}></input> */}
        //         </div>
        //         <br></br>
        //         {/* <button onClick={createNFT} className="font-bold mt-10 w-full bg-purple-500 text-white rounded p-2 shadow-lg"> */}
        //         <button type="submit" className="font-bold mt-10 w-full bg-purple-500 text-white rounded p-2 shadow-lg">
        //             List NFT for Sale
        //         </button>
        //     </form>
        // </div>
        // </div>
    )
}
