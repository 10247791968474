
function UserAgreement() {
    return(
        <div className="w-10/12 mx-auto">
            Global User Agreement<br />
Last Updated: June 2022<br />
1.
 
Global User Agreement<br />
SCTicket provides an online platform and related services (“Services”) that connects sellers and buyers seeking to respectively sell and buy tickets, related passes and merchandise or other related goods and/or services for events (“Tickets”) and we may make such Services available internationally via one or more of our websites, our mobile applications and/or associated services (collectively, the “Site”). By accessing or using our Site, You agree to be bound by this StubHub Marketplace Global User Agreement (“User Agreement”). Our User Privacy Notice, Mobile Privacy Notice, Seller Policies, Cookie Notice, FanProtect Guarantee, Top Seller Handbook (if you qualify as a Top Seller and the top seller program is available in your territory) and all other policies applicable to your use of the Site (collectively, the “Additional Policies”) are incorporated by reference into this User Agreement. We may periodically make changes to this User Agreement and shall notify you by posting a revised version on our Site. The revised User Agreement will become effective upon publication and your continued use of our Site and Services will constitute acceptance of the revised User Agreement.


FOR ALL USERS RESIDING IN THE UNITED STATES, PLEASE BE ADVISED: CLAUSE 22 OF THIS AGREEMENT CONTAINS AN AGREEMENT TO ARBITRATE, WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, UNLESS YOU OPT-OUT. UNLESS YOU OPT OUT: (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.

<br />2.
 
Contracting Entity<br />

2.1.
SCTicket is hereinafter referred to as "we", "us", or "our".

<br />3.
 
Ticket Marketplace<br />
3.1.
We are a ticket marketplace that allows registered users to buy (in this capacity, "Buyer") and sell (in this capacity, "Seller") Tickets. The Seller (and not us) sets the prices for the Tickets. As a marketplace, we do not own the Tickets sold on the Site. Ticket prices may exceed the face value of the Ticket.
3.2.
While we may provide pricing, shipping, listing and other guidance on the Site, such guidance is solely informational (without any warranty as to accuracy). Also, while we may help facilitate the resolution of disputes between Buyer and Seller and provide the FanProtect Guarantee, we have no control over and do not guarantee the existence, quality, safety or legality of the Tickets; the truth or accuracy of the user’s content or listings; the ability of Sellers to sell tickets or Buyers to pay for them; or that a Buyer or Seller will complete a transaction.
<br />4.
 
Your Account<br />
4.1.
In order to list, sell or purchase Tickets you must register an account ("Account") with us and agree to accept the terms in this User Agreement. You must be at least 18 years old and must be able to enter into legally binding contracts. If you are registering with us for a business entity, you represent that you have the authority to legally bind that entity.
4.2.
When opening an Account you must provide complete and accurate information including but not limited to your full name, address, phone number, email address and provide us with a valid credit card, debit card or PayPal account ("Payment Method"). If your registration or payment information changes at any time, you must promptly update your details in your Account. You acknowledge that we may charge any one of your payment methods on file for amounts you owe us, costs we incur or other losses arising out of your violation of this Agreement (including the Additional Policies).
4.3.
We reserve the right to temporarily or permanently suspend Accounts with incomplete or inaccurate information.
4.4.
You are solely responsible for maintaining the confidentiality of your Account information and password. You are solely responsible for any activity relating to your Account. Your Account is not transferable to another party.
<br />5.
 
Privacy and Communications<br />
All communications between you and us (including communications with our service providers, our parent company and/or our affiliates) are subject to our User Privacy Notice.


In addition, if you reside in the United States, you understand and agree that we may, without further notice or warning and in our discretion, monitor or record telephone conversations you or anyone acting on your behalf has with us or our agents for quality control and training purposes or for our own protection. Not all telephone lines or calls may be recorded by us so we do not guarantee that recordings of any particular telephone calls will be retained or retrievable.

<br />6.
 
Fees and Other Charges<br />
6.1.
We may charge fees for selling and/or buying Tickets through our Site and/or otherwise using our Services, as well as delivery or fulfillment fees (collectively referred to as "Service Fees"). Service Fees may vary depending on event type, Ticket type and location. We may in our sole and absolute discretion change our Service Fees at any time, including after you list your Tickets. Any applicable Service Fees including any taxes if applicable will be disclosed to you prior to listing or buying a Ticket. We may charge and/or retain Service Fees if you do not fulfill your contractual obligations under this User Agreement.
6.2.
If you as a Seller do not fulfill your contractual obligations under this User Agreement and the Seller Policies, we may charge you certain fees and/or costs in accordance with the Seller Policies.
6.3.
If you as a Buyer change your postal address after you have purchased your Ticket(s) and require us to deliver your Ticket(s) to this new postal address, or if you have specified an incorrect postal address, we may charge you for any additional delivery costs which arise out of re-arranging the delivery of your Ticket(s) to the new or correct postal address. Such additional delivery costs may be charged to your Payment Method.
6.4.
We may collect amounts owed using a collection agency or other collection mechanism and you may be charged fees associated with collecting the delinquent payments. We, or the collection agency we retain, may also report information about your account to credit bureaus, and as a result, defaults in your account may be reflected in your credit report. If you wish to dispute the information we reported to a credit bureau, please contact us. If you wish to dispute the information a collection agency reported, contact the collection agency directly.
<br />7.
 
Selling Tickets<br />
7.1.
You must comply with all applicable laws and regulations and the terms of this User Agreement and the Seller Policies when listing, selling and delivering your Tickets.

7.2.
When listing a Ticket you must set a price for which you are willing to sell your Ticket ("Sales Price"). You may modify (e.g. raise or lower the Sales Price) or delete your listing at any time until the Tickets have sold.

7.3.
By listing a Ticket for sale, you are making a binding offer to sell that Ticket to a Buyer who purchases the Ticket at the Sales Price. When a Buyer accepts your offer by purchasing your Ticket through our Site, you are contractually bound to deliver that exact Ticket for Sales Price and within the required delivery timeframe. You are obligated to monitor your inventory and ensure all listings are accurate. Under no circumstances may Sellers cancel orders at one price and repost the same tickets for a higher price. Failure to fulfill your orders will lead to charges as stated in this User Agreement and Seller Policies.

7.4.
We do not guarantee that your tickets or related passes will sell or that your listing will appear on the Site within a certain time after it is posted or in a particular order on the event page or through search results. We will not, for any reason, provide compensation for tickets that do not sell, even if it is due to Site unavailability from an outage or maintenance or listing delays.


Please refer to the Seller Policies for additional terms which apply to you as a Seller.

<br />8.
 
Taxes<br />
8.1.
You as a Seller are responsible for determining whether any taxes are due on your sale of a Ticket, and for collecting and remitting such taxes, except where we are required by law to calculate, collect, and remit sales tax on those sales. Any applicable taxes must be included in the Sales Price of your Ticket. You agree to provide us with your Social Security Number or other tax identification number if necessary for us to provide (without notice to You) information to the relevant tax authorities related to payments you receive from us and further authorize us to release that information to the relevant tax authorities. The Service Fees include any applicable sales, use, excise, value added, service and other indirect taxes.
8.2.
You agree that we are not responsible in any way for the accuracy or suitability of any payment of taxes to any entity on your behalf, except where we are required by law to calculate, collect, and remit sales tax on your sales. You shall indemnify and hold us and (if applicable) any parents, subsidiaries, affiliates, officers, directors, agents and employees harmless against all liabilities, costs, interest and expenses (including reasonable attorneys’ fees) incurred by us that arise out of any third party or governmental claim that involves, relates to or concerns (i) any local, regional, country, or international tax obligation or amounts due or owing under any tax regulation, law, order or decree or (ii) any dispute concerning our tax status.
<br />9.
 
Buying Tickets<br />
You are responsible for reading the complete listing before making a commitment to buy Tickets. When placing an order, you are entering into a binding contract with the Seller to purchase those Tickets. Additional terms communicated to you by the Seller may apply. Payment is remitted to StubHub and disbursed to the seller according to our User Agreement. All Sales are final. You cannot change or cancel any orders after the sale is complete.

<br />10.
 
Payments<br />
10.1.
Payments received from Buyers for Tickets purchased via the Site are processed by us on behalf of the Seller and credited to the Seller in accordance with the Seller Policies. Seller appoints us as its limited payment collection agent solely for the purpose of accepting funds from Buyers on behalf of Seller. Seller agrees that any payment made by Buyer to us shall be considered the same as a payment made directly to Seller, and Seller will make the Tickets available to Buyer in the agreed-upon manner as if Seller had received the payment directly from Buyer. Except as otherwise specified in the FanProtect Guarantee, Seller understands that our obligation to pay Seller is subject to and conditional upon successful receipt of the associated payments from Buyer. Except as otherwise specified in the FanProtect Guarantee, we guarantee payments to Seller only for such amounts that have been successfully received by us from Buyer. In accepting appointment as the limited payment collection agent of seller, we assume no liability for any acts or omissions of the Seller.

10.2.
Payments from Buyers are due and payable immediately. A Buyer can pay the full order amount which includes any applicable Service Fees and taxes using one of the accepted payment methods.
10.3.
A Buyer’s obligation to pay for a Ticket is satisfied when we has received the Payment in full.
<br />11.
 
Event Cancellations, Postponement and Other Event Changes<br />
11.1.
Cancellation: If an event is canceled and not rescheduled, we will remove the event and any listings related to the event from our Site and inform both Buyer and Seller about the cancelation with further instructions. If the Seller requires the Seller’s Ticket(s) back to obtain a refund of the original purchase price from the promoter or organizer, the Seller must contact Customer Service immediately after the cancelation. The Buyer is required to retain the Ticket(s) and upon our request to send them back to either the Seller or to us (as instructed by us). The Buyer will receive a full refund or credit for use on a future purchase, as determined in our sole discretion (unless a refund is required by law) once the Seller has sent back the Ticket(s) (if applicable) and the Seller will not be paid. If the Seller has already been paid, the payment for the sale may be recovered by charging the Seller's Payment Method or by setting off this amount against pending payments for other Tickets the Seller has sold or will sell in the future.

11.2.
Postponement: If an event is postponed, we will work with Buyers and Sellers on a case-by-case basis attempting to resolve any Ticket issues. Sellers are prohibited from reselling, invalidating or changing Tickets for postponed events. Refunds will not be issued for postponed events, unless they are ultimately canceled as stated above.

11.3.
Other event changes: We are not responsible for partial performances, venue, line-up or time changes. No refunds will be issued in these instances.

11.4.
For Quebec residents only: If an event is postponed or rescheduled, the Buyer will receive a full refund upon a request being made and once the Buyer has sent back the Ticket (s).

<br />12.
 
International Transactions<br />
12.1.
Many of our Services are accessible to international Sellers and Buyers. We may offer certain programs, tools, and site experiences of particular interest to international Sellers and Buyers, such as estimated local currency conversion.
12.2.
Your Ticket listing may appear on one or more of our international Sites (including the original Site in which you listed your ticket). Sellers and Buyers are responsible for complying with all laws and regulations applicable to the international sale, purchase, and shipment of Tickets. If you are a consumer seller and do not wish your Ticket listing to be visible internationally, you will need to remove your listing from our Site.
12.3.
A Ticket to an event taking place in another location from the location of the Site that you listed or bought your Ticket on, or Site that you registered or accessed to sell or purchase your Ticket on (together “Local Site” ) is defined as an “International Event” . You may be subject with respect to that particular sale or purchase to certain Additional Policies of the Site that relate to the location of the International Event.

12.4.
As a Buyer, if you are purchasing a Ticket from your Local Site to an International Event, information relating to the Ticket and/or the event may differ to the information that you may receive on your Local Site, as information requirements may vary according to local laws.
12.5.
As a Seller, if you are selling a Ticket from your Local Site to an International Event, (i) information required by us relating to the Ticket and/or the International Event may differ from the information that you may provide on your Local Site, as information requirements may vary according to local laws, and (ii) you may be required to list the Ticket in the currency of the location of the International Event and your payment service provider may convert the Ticket price and apply additional charges.
12.6.
To assist users who speak different languages, you authorize us to translate (or use tools to translate) your content related to your Ticket listing, in whole or in part, into local languages where such translation solutions are available. The accuracy and availability of any translation are not guaranteed.
12.7.
Where as a Seller your Ticket listing appears on one or more of our international Sites, we may as part of our Services display the price of your Ticket in one or more currencies (we reserve the right not to display your Ticket Sales Price in the currency related to the Local Site in which you listed Your Ticket), such information will be provided for informational purposes only (without any warranty as to accuracy) and the Buyer will be required to pay for any such Ticket in the currency applicable to the event.
12.8.
In order to promote Ticket listings and increase their exposure to potential Buyers, listings may also be displayed on our other Sites or third-party websites and mobile application and within emails and onsite advertisements.
<br />13.
 
Term and Termination<br />
13.1.
This User Agreement shall commence on the date that you register an Account with us and will continue for an indefinite period unless terminated in accordance with these provisions.
13.2.
You may terminate this User Agreement at any time by contacting Customer Service. In the event that you request that we close your Account, we will treat your request as termination of this User Agreement. Without limiting our rights according to Clause 19, we may terminate this User Agreement at any time by giving at least thirty (30) days’ notice via email to your registered email address. We will then close your Account.

13.3.
Termination of this User Agreement shall not affect the rights or liabilities of either party accrued prior to and including the date of termination or expiry and/or any terms intended expressly or by implication to survive termination or expiry.
<br />14.
 
Site Changes and Availability<br />
We reserve the right at any time to modify or discontinue, temporarily or permanently, the Site, the Services or any part of the Site or Services with or without notice for any reason. We perform regularly-scheduled maintenance. While we do our best to avoid customer impact, the Site or our Services may be temporarily unavailable during maintenance periods.
<br />15.
 
Abusing Our Site and Services<br />
When using our Site and Services, you agree that you will not do any of the following:
contact or invite contact with other users for any reason other than the purpose for which you received the user’s contact information from us or to solicit sales outside of our Site;

use the Buyer's personal data for any reason other than the delivery of Tickets unless otherwise agreed to by the Buyer;

behave in an abusive manner to any of our employees or other users;

violate any venue or event promoter rules at events or violate any applicable third party terms of service (for instance, when using our mobile applications);

breach or circumvent any laws (including, where Tickets are sold internationally, the laws of the destination country), third party rights or our Additional Policies;

post false, inaccurate, misleading, defamatory or libelous content;

fail to fulfill your contractual obligations regarding the sale or purchase of a Ticket;

use our trademarks without our prior written permission;

copy, reproduce, reverse engineer, modify, create derivative works from, distribute or publicly display any content (except for your information) or software from our Site or Services without our prior express written permission and the appropriate third party, as applicable;

use any robot, spider, scraper or other automated means to access our Site or Services for any purpose without our express written permission;

take any action that imposes or may impose (to be determined in our sole discretion) an unreasonable or disproportionately large load on our infrastructure;

interfere or attempt to interfere with the proper working of our Site or Services or any activities conducted on or with our Site or Services;

bypass our robot exclusion headers, robots.txt rules or any other measures we may use to prevent or restrict access to our Site or Services;

commercialize any of our applications or any information or software associated with such application;

export or re-export any of our applications or tools except in compliance with the export control laws of any relevant jurisdiction and in accordance with posted rules and restrictions; or

do anything else that we determine, in our sole reasonable discretion, misuses the Site or Services or otherwise negatively impacts our marketplace.

In our effort to protect our Buyers and Sellers we may screen ticket sales or listings for signs of fraud using algorithms or automated systems, which may result in automatic cancelation of listings or sales and temporary or permanent suspension of accounts. If your transaction is canceled in error, or your account suspended in error, please contact us and we may reinstate your Account or listing, in our sole discretion.
<br />16.
 
Mobile Device Terms<br />
If you are accessing the Site or Services through a mobile application (“App”), the following additional terms apply:

16.1.
App Use. We grant you the right to use the App only for your personal use. You must comply with all applicable laws and third party terms of agreement when using the App (e.g. your wireless data service agreement). The App may not contain the same functionality as available on our applicable website.
16.2.
Intellectual Property – Apps. We own, or are the licensee to, all right, title, and interest in and to the Apps, including all rights under patent, copyright, trade secret, trademark, or unfair competition law, and any and all other proprietary rights, including all applications, renewals, extensions, and restorations thereof. You will not modify, adapt, translate, prepare derivative works from, decompile, reverse-engineer, disassemble, or otherwise attempt to derive source code from any App and you will not remove, obscure, or alter our copyright notice, trademarks or other proprietary rights notices affixed to, contained within, or accessed in conjunction with or by the App.
16.3.
Prohibited Countries Policy and Foreign Trade Regulation - Applications. The App or its underlying technology may not be downloaded to or exported or re-exported: (a) into (or to a resident or national of) Burma (Myanmar), Cuba, Iraq, Iran, Libya, North Korea, Sudan, Syria, or any other country subject to United States embargo; (b) to anyone on the US Treasury Department's list of Specially Designated Nationals or on the US Commerce Department's Denied Party or Entity List; and (c) to any prohibited country, person, end-user, or entity specified by US Export Laws. When using the App, you are responsible for complying with trade regulations and both foreign and domestic laws (e.g., you are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a "terrorist supporting" country, and you are not listed on any US Government list of prohibited or restricted parties).
16.4.
Device Specific Additional Terms


iOS - Apple
These Mobile Device Terms are an agreement between you and us, and not with Apple. Apple is not responsible for the App and the content thereof.

We grant you the right to use the App only on an iOS product that you own or control and as permitted by the App Store Terms of Service.

Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the App.

Apple is not responsible for the investigation, defense, settlement, and discharge of any third party intellectual property infringement claim.

Apple is not responsible for addressing any claims by you or any third party relating to the App or your possession and/or use of the App, including but not limited to: (a) product liability claims; (b) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or similar legislation.

In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price, if applicable, for the App to you; and to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the App.

Apple and Apple's subsidiaries are third party beneficiaries of these Mobile Device Terms, and, upon your acceptance, Apple as a third party beneficiary thereof will have the right (and will be deemed to have accepted the right) to enforce these Mobile Device Terms against you.


Android - Google
These Mobile Device Terms are an agreement between you and us, and not with Google. Google is not responsible for the App and the content thereof.

We grant you the right to use the App only on an Android product that you own or control and as permitted by the Google Play Terms of Service.

Google has no obligation whatsoever to furnish any maintenance and support services with respect to the App.

Google is not responsible for the investigation, defense, settlement, and discharge of any third party intellectual property infringement claim.

Google is not responsible for addressing any claims by you or any third party relating to the App or your possession and/or use of the App, including but not limited to: (a) product liability claims; (b) any claim that the Application fails to conform to any applicable legal or regulatory requirement; and (c) claims arising under consumer protection or similar legislation.

In the event of any failure of the App to conform to any applicable warranty, you may notify Google, and Google will refund the purchase price, if applicable, for the App to you; and to the maximum extent permitted by applicable law, Google will have no other warranty obligation whatsoever with respect to the App.

Google and Google's subsidiaries are third party beneficiaries of these Mobile Device Terms, and, upon your acceptance, Google as a third party beneficiary thereof will have the right (and will be deemed to have accepted the right) to enforce these Mobile Device Terms against you.

<br />17.
 
Content<br />
17.1.
You own and are solely responsible for any content including images, text, audio or other materials that you submit (“User Content”). By submitting User Content, you grant us a non-exclusive, worldwide, perpetual (or for the duration of any copyright or other rights in such content), irrevocable, royalty-free, transferable, sublicensable (through multiple tiers) right to use, reproduce, distribute, create derivative works from, publicly perform, display, store, or publish in any way, in any media known now or in the future. You grant us the right to use your name or image in association with your User Content, if we so choose. Further, to the fullest extent permitted under applicable law, you waive your moral rights in the content and promise not to assert such rights against us, our sublicensees or assignees.

17.2.
You represent and warrant that the User Content: (a) is owned by you or you control all necessary rights to it; (b) does not violate, misappropriate or infringe on the rights of any third party including intellectual property rights, privacy rights or publicity rights; (c) is accurate, not obscene, false, libelous or defamatory; and (d) will not cause injury to any person, entity or system. You will indemnify us for any and all claims resulting from your User Content. We have the right but not the obligation to monitor, edit or remove your User Content. The User Content is non-confidential and we will not be liable for its use or disclosure. The sale of stolen property on our Site is strictly forbidden and violates local, country and international law. We strongly support law enforcement efforts to recover stolen property that is listed on the Site, and we urge the prosecution of those responsible for knowingly attempting to sell such items on the Site. Stolen property includes items taken from private individuals, as well as property taken without authorisation from companies or governments.
17.3.
We may offer catalogs of stock images, descriptions and product specifications, which are provided by third parties (including our users). You may use catalog content solely in connection with your listings with us during the time your listings are on our Site. While we try to offer reliable data, we cannot promise that the catalogs will always be accurate and up-to-date, and you agree that you will not hold our catalog providers or us responsible for inaccuracies in their catalogs. The catalog may include copyrighted, trademarked or other proprietary materials. You agree not to remove any copyright, proprietary or identification markings included within the catalogs or create any derivative works based on catalog content (other than by including them in your listings).
17.4.
If you submit ideas, suggestions, documents, and/or proposals ("Ideas") to us, you do so voluntarily and with no expectation of confidentiality. By submitting Ideas, you grant us ownership of the Ideas to use for any purpose, in any way, in any media now known or in the future and throughout the universe without any obligation or payment. You understand that we may have something similar to the Ideas under consideration or in development.

<br />18.
 
Protecting Intellectual Property Rights<br />
We respect intellectual property rights and we respond to notices of alleged infringement.
If you reside in the United States, and you find material on our Site or Services that you believe infringes your copyright or other intellectual property rights, please notify us and we will investigate. Learn how to submit a notice to us, see Appendix 1.
<br />19.
 
Violating the Agreement<br />
19.1.
We may investigate any potential or suspected violations of the User Agreement, Additional Policies, security protocols or best practices, third party rights or applicable law; any actions or any other misconduct or potential abuse on or through the Services or attempts. When assessing whether and which measure(s) to take against a user, we will take the legitimate interests of the user into account and shall, in particular, consider if the user is responsible for the misconduct.
19.2.
We may take any actions we deem appropriate in our reasonable discretion for the conduct described above. Without limiting other remedies, these actions may include: limit, or temporarily or permanently suspend, or terminate our services and Accounts, restrict or prohibit access to, and your activities on, our Site and/or Services, remove listings, require you to edit listings, cancel sales, require you to send Tickets to a Buyer within a specified time, delay or remove hosted content, remove any special status associated with an Account, reduce or eliminate any discounts, withhold payment, charge the Payment Method on file for amounts you owe or costs we incur due to your misconduct (including, without limitation, any costs associated with collection of delinquent Accounts or chargebacks and any replacement costs), and take technical and legal steps to keep you from using our Site and/or Services.
19.3.
We reserve the right to report any activity that we believe to be illegal or otherwise in contravention of this User Agreement and we will respond to any verified requests relating to a criminal investigation (i.e. a subpoena, court order or substantially similar legal procedure) from local and foreign law enforcement or regulatory agencies, other government officials or authorized third-parties.
<br />20.
 
Disclaimer of Warranties; Limitation of Liability<br />
20.1.
YOU UNDERSTAND AND AGREE THAT YOU ARE MAKING USE OF THE SITE AND SERVICES ON AN ‘AS IS’ AND ‘AS AVAILABLE’ BASIS. WE MAKE NO WARRANTY WITH RESPECT TO ITS SOFTWARE, SERVICES, SITE, ANY TICKETS, ANY EVENT, ANY USER CONTENT, OR THAT SELLERS OR BUYERS WILL PERFORM AS PROMISED. ACCORDINGLY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS SPECIFICALLY PROVIDED IN THIS USER AGREEMENT AND IN OUR FANPROTECT GUARANTEE, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.
20.2.
IN ADDITION, TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE (INCLUSIVE OF ANY OF ITS SERVICE PROVIDERS AND LICENSORS) ARE NOT LIABLE FOR: (A) ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES; (B) LOSS OF: PROFITS, GOODWILL OR REPUTATION, OR OTHER INTANGIBLE LOSSES; (C) DAMAGES RELATING TO: (I) YOUR ACCESS TO, USE OF, OR INABILITY TO ACCESS OR USE THE SITE OR SERVICES; (II) VIRUSES OR OTHER MALICIOUS SOFTWARE OBTAINED BY ACCESSING OUR SITE OR SERVICES OR THOSE OF ANY SITE, SERVICES, OR TOOLS LINKED TO OUR SITE OR SERVICES; (III) ANY USER CONTENT OR CONTENT OF THIRD PARTIES, OR (IV) THE DURATION OR MANNER IN WHICH TICKETS YOU LIST APPEAR ON THE SITE OR SERVICES.
20.3.
SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF WARRANTIES OR EXCLUSION OF DAMAGES, SO SUCH DISCLAIMERS AND EXCLUSIONS MAY NOT APPLY TO YOU AND YOU MAY HAVE RIGHTS IN ADDITION TO THOSE CONTAINED IN THIS USER AGREEMENT. THIS IS ESPECIALLY SO IF YOU ARE A CONSUMER.
20.4.
REGARDLESS OF THE PREVIOUS CLAUSES, OUR LIABILITY TO YOU OR TO ANY THIRD PARTY IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES IN DISPUTE NOT TO EXCEED THE TOTAL FEES WHICH YOU PAID TO US IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO THE LIABILITY, OR (B) $200 (OR €200, AS APPLICABLE).
<br />21.
 
Applicable Laws and Jurisdiction<br />
21.1.
If you reside in the United States or Canada, you agree to the maximum extent permissible by applicable law that any claim, dispute or matter arising under or in connection with this User Agreement shall be governed and construed according to the laws of the State of California. You also agree to resolve your disputes with us as specified in Clause 22.
21.2.
If you reside anywhere else in the world, you agree that any claim, dispute or matter arising under or in connection with this User Agreement shall be governed and construed according to Swiss law. You consent to the exclusive personal jurisdiction and venue of Swiss courts.
21.3.
If you are a consumer residing in the European Union, please note that we do not (and are not legally obliged to) participate in any alternative dispute resolution (ADR) procedures or services, unless otherwise provided by law. Please note that the mandatory consumer protection provisions of the law of the member state in which you reside will also apply.
<br />22.
 
Legal Disputes<br />
22.1.
If you reside in the United States or Canada, You and us each agree, except where prohibited by law, that any and all disputes or claims that have arisen or may arise between you and us relating in any way to or arising out of this or previous versions of the User Agreement (including this Agreement to Arbitrate, as the term is defined below) or the breach or validity thereof, your use of or access to the Site or Services, or any tickets or related passes sold or purchased through the Site or Services shall be resolved exclusively through final and binding arbitration administered by the American Arbitration Association ("AAA") in accordance with its Consumer Arbitration Rules (“Rules”), rather than in court, except that you may assert claims in small claims court, if your claims qualify and so long as the matter remains in such court and advances only on an individual (non-class, non-representative) basis (together with subsections 22(A)-(F), the “Agreement to Arbitrate”). This Agreement to Arbitrate is intended to be broadly interpreted. The Federal Arbitration Act governs the interpretation and enforcement of this Agreement to Arbitrate.

A. Prohibition of Class and Representative Actions and Non-Individualized Relief
1. Prohibition of Class and Representative Actions
EXCEPT WHERE PROHIBITED BY LAW, YOU AND US AGREE THAT EACH OF US MAY BRING CLAIMS PURSUANT TO THIS AGREEMENT TO ARBITRATE AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING. UNLESS BOTH YOU AND US AGREE OTHERWISE, THE ARBITRATOR SHALL NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR PARTY'S CLAIMS AND SHALL NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, CLASS, OR PRIVATE ATTORNEY GENERAL ACTION OR PROCEEDING.
2. Non-Individualized Relief
YOU AND US AGREE THAT THE ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL CLAIM(S). If a court decides that applicable law precludes enforcement of any of this paragraph's limitations as to a particular request for relief within a claim, or if not separable the claim itself, then subject to your and our right to appeal the court’s decision, that request for relief within that claim, or if not separable the claim itself, (and only that request for relief within that claim or, if inseparable, that claim) must be severed from the arbitration and may be brought in court. All other claims will be arbitrated.

B. Arbitration Procedures
Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, and court review of an arbitration award is very limited. However, an arbitrator can award the same damages and relief on an individual basis that a court can award to an individual; and an arbitrator must also follow the terms of the User Agreement, as a court would.
All issues, including arbitrability, are for the arbitrator to decide, except the scope or enforceability of this Agreement to Arbitrate or the interpretation of Section 1 of this Agreement to Arbitrate ("Prohibition of Class and Representative Actions and Non-Individualized Relief"), shall be for a court of competent jurisdiction to decide.
The arbitrator, and not any federal, state, or local court or agency, shall have exclusive authority to resolve any dispute arising out of or relating to the interpretation, applicability, enforceability or formation of this Agreement to Arbitrate, any part of it, or of the User Agreement including, but not limited to, any claim that all or any part of this Agreement to Arbitrate or the User Agreement is void or voidable.
The arbitration will be conducted by the AAA under the Rules, as modified by this Agreement to Arbitrate. The Rules are available at www.adr.org or by calling the AAA at 1-800-778-7879. The language of the arbitration shall be English.
A party who intends to seek arbitration must first send to the other, by certified mail, a completed form Notice of Dispute (“Notice”). You may download a form Notice. A Notice to us should be sent to StubHub Inc., Attn: Customer Experience Department, Re: Notice of Dispute, PO Box 448, 12180 S 300 E, Draper, UT 84020 United States. We will send any Notice to you to the physical address we have on file associated with your account; it is your responsibility to keep your physical address up to date. All information called for in the Notice must be provided, including a description of the nature and basis of the claims the party is asserting and the relief sought.
If you and us are unable to resolve the claims described in the Notice within 30 days after the Notice is sent, you or us may initiate arbitration proceedings pursuant to the Rules. A form for initiating arbitration proceedings is available on the AAA's website at www.adr.org. In addition to filing this form with the AAA in accordance with its rules and procedures, the party initiating the arbitration must mail a copy of the completed form to the opposing party. You may send a copy to us at the following address: StubHub, Inc. c/o CT Corp. 1209 Orange Street, Corporation Trust Center, Wilmington, DE 19801. In the event we initiate arbitration against you, it will send a copy of the completed form to the physical address we have on file associated with your account. Any settlement offer made by you or us shall not be disclosed to the arbitrator. The arbitration shall be held in the county in which you reside or at another mutually agreed location. If the value of the relief sought is $10,000 or less, you or us may elect to have the arbitration conducted by telephone or based solely on written submissions, which election shall be binding on you and us subject to the arbitrator's discretion to require an in-person hearing, if the circumstances warrant. In cases where an in-person hearing is held, you and/or us may attend by telephone, unless the arbitrator requires otherwise.
The arbitrator will decide the substance of all claims in accordance with the laws of the State of California, including recognized principles of equity, and will honor all claims of privilege recognized by law. The arbitrator shall not be bound by rulings in prior arbitrations involving different users of ours, but is bound by rulings in prior arbitrations involving the same user to the extent required by applicable law. The arbitrator's award shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.

C. Costs of Arbitration
Payment of all filing, administration, and arbitrator fees will be governed by the Rules, unless otherwise stated in this Agreement to Arbitrate. If the value of the relief sought is $10,000 or less, at your request, we will pay all filing, administration, and arbitrator fees associated with the arbitration. Any request for payment of fees by us should be submitted by mail to the AAA along with your Demand for Arbitration and we will make arrangements to pay all necessary fees directly to the AAA. If the value of the relief sought is more than $10,000 and you are able to demonstrate that the costs of accessing arbitration will be prohibitive as compared to the costs of accessing a court for purposes of pursuing litigation on an individual basis, we will pay as much of the filing, administration, and arbitrator fees as the arbitrator deems necessary to prevent the costs of accessing arbitration from being prohibitive. If (a) you willfully fail to comply with the Notice of Dispute requirement discussed in subsection 22(B) above, or (b) in the event the arbitrator determines the claim(s) you assert in the arbitration to be frivolous, you agree to reimburse us for all fees associated with the arbitration paid by us on your behalf that you otherwise would be obligated to pay under the Rules.

D. Severability
With the exception of any of the provisions in subsection A.1 of this Agreement to Arbitrate (“Prohibition of Class and Representative Actions”), if an arbitrator or court decides that any part of this Agreement to Arbitrate is invalid or unenforceable, the other parts of this Agreement to Arbitrate shall still apply. If an arbitrator or court decides that subsection A.1 of this Agreement to Arbitrate is invalid or unenforceable, then if we so elect, the entirety of this Agreement to Arbitrate shall be null and void. If a court decides that applicable law precludes enforcement of any of the provisions in subsection A.2 of this Agreement to Arbitrate (“Non-Individualized Relief”) as to a particular request for relief within a claim, or if not separable the claim itself, then subject to your and our right to appeal the court’s decision, that request for relief within that claim, or if not separable the claim itself, (and only that request for relief within that claim or, if inseparable, that claim) must be severed from the arbitration and litigated in in court. All other claims and disputes subject to arbitration under this Agreement to Arbitrate, including any and all claims for monetary damages of any kind, shall be arbitrated.

E. Opt-Out Procedure
You can choose to reject this Agreement to Arbitrate (‘opt out’) by mailing us a written opt-out notice (‘Opt-Out Notice’). The Opt-Out Notice must be postmarked no later than 30 days after the date you accept the User Agreement for the first time. You may also opt out no later than 30 days upon receipt of notice that the arbitration provision is being updated or changed. You must mail the Opt-Out Notice to StubHub, Inc., Attn: Litigation Department, Re: Opt-Out Notice, 1209 Orange Street, Corporation Trust Center, Wilmington, DE 19801.
For your convenience, we are providing an Opt-Out Notice form you must complete and mail to opt out of the Agreement to Arbitrate. You must complete the Opt-Out Notice form by providing the information called for in the form, including your name, address (including street number and address, city, state, and zip code), phone number and the email address(es) used to log in to the account(s) to which the opt-out applies. You must sign the Opt-Out Notice for it to be effective. This procedure is the only way you can opt out of the Agreement to Arbitrate. If you opt out of the Agreement to Arbitrate, all other parts of the User Agreement will continue to apply. Opting out of this Agreement to Arbitrate has no effect on any previous, other, or future arbitration agreements that you may have with us.

F. Future Amendments to the Agreement to Arbitrate
Notwithstanding any provision in the User Agreement to the contrary, you and us agree that if we make any amendment to this Agreement to Arbitrate (other than a change to any notice address or website link provided herein) in the future, that amendment shall not apply to any claim that was filed in a legal proceeding between you and us prior to the effective date of the change. The amendment shall apply to all other disputes or claims governed by the Agreement to Arbitrate that have arisen or may arise between you and us . We will notify you of amendments to this Agreement to Arbitrate by posting the amended terms on https://www.stubhub.com at least thirty (30) days before the effective date of the amendments and by sending notice via email to your email address on file with us. If you do not agree to the amended terms, you may close your account within the thirty (30) day period and you will not be bound by the amended terms.
22.2.
If you reside outside of the United States or Canada and a dispute arises between you and us, we strongly encourage you to first contact us directly to seek a resolution by contacting Customer Service. For the avoidance of doubt, if you are a consumer residing in the European Union, please note that we do not (and are not legally obliged to) participate in any ADR procedures or services, unless otherwise provided by law.
<br />23.
 
Release and Indemnification<br />
23.1.
To the fullest extent permitted by law, you release and covenant not to sue us, our affiliated companies, and our and their respective officers, directors, agents, joint venturers, employees, legal representatives, and suppliers from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with disputes between users. In entering into this release you expressly waive any protections (whether statutory or otherwise – e.g., California Civil Code § 1542) that would otherwise limit the coverage of this release to include only those claims, which you may know or suspect to exist in your favor at the time of agreeing to this release.
23.2.
You agree to indemnify and hold us and (if applicable) our parent, subsidiaries, affiliates, and our and their respective officers, directors, attorneys, agents, employees, licensors and suppliers (the "Company Indemnitees") harmless against any claim or demand and all liabilities, costs and expenses (including reasonable attorneys' fees) incurred by us and (if applicable) any Company Indemnitee resulting from or arising out of your breach of this User Agreement, your improper use of our Site or Services, and/or your violation of any law or the rights of a third party.
<br />24.
 
Additional Provisions
Applicable to US residents<br />
24.1.
If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.

Applicable to events located in the state of Illinois (USA)
24.2.
For any user that is selling a Ticket to an event located in the state of Illinois, the following applies: pursuant to 815 ILCS 414/1.5(c), for transactions involving tickets to events in Illinois, Buyers and Sellers may elect to submit complaints against one another to the American Arbitration Association (‘AAA’) under its rules and procedures. The AAA's rules are available at www.adr.org. Such complaints shall be decided by an independent arbitrator in accordance with this User Agreement and all incorporated Policies. Buyers and Sellers further agree to submit to the jurisdiction of the State of Illinois for complaints involving a ticketed event held in Illinois.
<br />25.
 
General Provisions<br />
25.1.
This User Agreement (and all documents incorporated by reference) constitutes the entire agreement between the parties pertaining to the subject matter hereof and supersedes all prior agreements and understandings between the parties hereto with respect to the subject matter hereof, whether written or oral. No amendment, modification or supplement of any provision of this User Agreement will be valid or effective unless made in accordance with the express terms of this User Agreement.
25.2.
If any provision of this User Agreement is held to be invalid or unenforceable under any circumstances, its application in any other circumstances and the remaining provisions of this User Agreement shall not be affected.
25.3.
We may assign or transfer our rights and obligations under this User Agreement at any time, provided that we assign the User Agreement on the same terms or terms that are no less advantageous to you. You may not assign or transfer this User Agreement, or any of its rights or obligations, without our prior written consent, which we can refuse in our sole discretion.
25.4.
No agency, partnership, joint venture, employer-employee or franchisor-franchisee relationship is intended or created by this User Agreement, your use of the Site, or the provision of our Services. A person who is not a party to this User Agreement has no right to enforce any term of this User Agreement.
25.5.
Nothing in this User Agreement is intended to confer benefits, rights or remedies unto any person or entity other than the parties hereto and their permitted successors and assigns.
25.6.
The subject heading at the beginning of each paragraph of this User Agreement is for reference purposes only and in no way defines, limits, construes or describes the scope or extent of such paragraph. The following sections survive any termination of this User Agreement: Contracting entity, fees and other charges, taxes, payments, abusing our site and services, content, liability, applicable laws, release and indemnification, additional provisions applicable to US residents and events in the USA, general provisions.
25.7.
Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this User Agreement.
25.8.
Legal notices to us shall be served by registered mail to your relevant entity listed in Clause 2.1. We shall send notices to you by email to the email address you provide to us during the registration process. Notice to you shall be deemed given 24 hours after the email is sent, unless we are notified that the email address is invalid. Alternatively, we may give you legal notice by registered mail to the postal address provided during registration. Notices sent to either party by registered mail shall be deemed to have been received by that party three days after the date of mailing.
<br />26.
 
Force Majeure<br />
We shall not be in default or otherwise liable under this User Agreement due to its inability to perform its obligations by reason of any fire, earthquake, flood, substantial snowstorm, epidemic, pandemic, accident, explosion, casualty, strike, lockout, labor controversy, riot, civil disturbance, act of public enemy, terrorism, cyber-terrorism, embargo, war, act of God, natural disaster, or any municipal, county, state or national ordinance or law, or any executive, administrative or judicial order (which order is not the result of any act or omission which would constitute a default by us hereunder), or any failure or delay of any transportation, power, or communications system or any other or similar cause not under our control (“Force Majeure”).
<br />27.
 
Appendix 1
To report infringement of your intellectual property appearing on stubhub.com

Notice of Infringement: If you have a good faith belief that your intellectual property rights have been violated, you can submit a Notice of Claimed Infringement to StubHub’s designated agent with the following information:

1. The physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;

2. Identification or description of the copyrighted work or other intellectual property you claim has been infringed. If you are asserting infringement of an intellectual property right other than copyright, please specify the right at issue (for example, trademark or patent);

3. Identification or description of where the material that you claim is infringing is located, with enough detail that we may find it on the Site including, whenever possible, the URL;

4. Brief description of how the challenged content infringes the owner’s intellectual property rights;

5. Your address, telephone number, and email address;

6. A statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright or intellectual property owner, its agent, or the law; and

7. A statement, under penalty of perjury, that the information in the notification is accurate and that you are the copyright or intellectual property owner or authorized to act on the owner’s behalf.

Our designated agent can be contacted as follows: by mail to our Designated Agent, CT Corp., 1209 Orange Street, Corporation Trust Center, Wilmington, DE 19801; by email to privacyrequest@orders.viagogo.com; or by phone at 1.855.420.3571.

To report infringement of your intellectual property appearing on any other domain

Contact Customer Service and provide your name, contact details and sufficient details to enable us to review your complaint.
        </div>
    );
}

export default UserAgreement;